<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 1.5em;") CÓMO SE CALCULA
          p #[strong Paso 1]- Se clasifican los productos de una familia de la oferta en tres categorías:
          p #[strong Price Entry]: Productos con precios mas bajos de la  familia  ( Recomendado:  aprox . 25% de items de la familia)
          p #[strong Price Rotation]: Productos con los precios que mas se acercan al precio medio que  queremos que el cliente gaste en esa familia ( Recomendado: aprox. 50% de items familia)
          p #[strong Price Premium]: Productos con los precios mas altos de la familia ( Recomendado:  aprox . 25% de items de la familia)

      div.d-flex.flex-column.justify-center(style="width: 100%")
        div.pt-15
          card_2_-s-v-g_1

  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 1.5em;") HOW IT IS CALCULATED
          p #[strong Step 1]- The products of a family of the offer are classified into three categories:
          p #[strong Price Entry]: Products with the lowest prices of the family (Recommended: approx. 25% of family items)
          p #[strong Price Rotation]: Products with the prices that are closest to the average price that we want the customer to spend on that family (Recommended: approx. 50% of family items)
          p #[strong Price Premium]: Products with the highest prices in the family (Recommended: approx. 25% of family items)

      div.d-flex.flex-column.justify-center(style="width: 100%")
        div.pt-15
          card_2_-s-v-g_1_en
</template>

<script>
import Card_2_SVG_1 from "./assets/Card_2_SVG_1";
import Card_2_SVG_1_en from "./assets/Card_2_SVG_1_en";

export default {
  name: "Card_2",
  components: {Card_2_SVG_1, Card_2_SVG_1_en}
}
</script>

<style scoped>

</style>
